/**
 * @generated SignedSource<<c05d581d67279c6246da65ffc0966327>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CreateUpdatePage_getOtaMachinesQuery$variables = {};
export type CreateUpdatePage_getOtaMachinesQuery$data = {
  readonly getOtaMachines: ReadonlyArray<{
    readonly id: string;
    readonly location: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly name: string | null;
    readonly remark: string | null;
    readonly serialNumber: string;
  }>;
};
export type CreateUpdatePage_getOtaMachinesQuery = {
  response: CreateUpdatePage_getOtaMachinesQuery$data;
  variables: CreateUpdatePage_getOtaMachinesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MachineObjectType",
    "kind": "LinkedField",
    "name": "getOtaMachines",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "remark",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serialNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LocationObjectType",
        "kind": "LinkedField",
        "name": "location",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateUpdatePage_getOtaMachinesQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CreateUpdatePage_getOtaMachinesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3508fd70c2c325ea871a94ddac391f90",
    "id": null,
    "metadata": {},
    "name": "CreateUpdatePage_getOtaMachinesQuery",
    "operationKind": "query",
    "text": "query CreateUpdatePage_getOtaMachinesQuery {\n  getOtaMachines {\n    id\n    name\n    remark\n    serialNumber\n    location {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3491a687ad014474c87232a732e8939b";

export default node;
