import React, { Suspense, useRef, useState, useEffect } from "react";
import Select, { components } from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { Formik, ErrorMessage } from "formik";
import Moment from "moment";
import * as yup from "yup";
import clsx from "clsx";
import $ from "jquery";
import { createRelease } from "../models/_createUpdate";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { CreateUpdatePage_getOtaMachinesQuery } from "./__generated__/CreateUpdatePage_getOtaMachinesQuery.graphql";
import { CreateUpdate_getBinariesQuery } from "./__generated__/CreateUpdate_getBinariesQuery.graphql";
import { CreateUpdate_getRscQuery } from "./__generated__/CreateUpdate_getRscQuery.graphql";
import { CreateUpdate_getScriptsQuery } from "./__generated__/CreateUpdate_getScriptsQuery.graphql";
import { CreateUpdate_getConfigVersionQuery } from "./__generated__/CreateUpdate_getConfigVersionQuery.graphql";
import MultiSelect from "./helpers/MySelect";
import Modal from "react-responsive-modal";
const validationSchema = yup.object({
  versionNo: yup.string().required("Version no is required"),
  otaMachines: yup.array().min(1, "Machine is required"),
  rscFile: yup.string(),
  launchDate: yup.date().required("Launch time is required"),
  scriptFile: yup.string().required("Update script file is required"),
  selectRscFile: yup.array().when("rscFile", {
    is: "SELECT",
    then: yup.array().min(1, "RSC file is required"),
  }),
  selectScriptFile: yup.string().when("scriptFile", {
    is: "SELECT",
    then: yup.string().required("Update script file is required"),
  }),
});

const getMachines = graphql`
  query CreateUpdatePage_getOtaMachinesQuery {
    getOtaMachines {
      id
      name
      remark
      serialNumber
      location {
        id
        name
      }
    }
  }
`;

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <label className="form-check form-check-sm form-check-custom form-check-solid align-items-center">
          <input
            className="form-check-input ms-2"
            type="checkbox"
            readOnly
            checked={props.isSelected}
          />
          <span className="ms-2 fs-5 form-check-label">{props.label}</span>
        </label>
      </components.Option>
    </div>
  );
};


const createUpdateValues: any = {
  binaryCommit: "",
  configVersion: "",
  versionNo: "",
  otaMachines: [],
  selectRscFile: [],
  selectScriptFile: "",
  rscFile: "",
  scriptFile: "",
  launchDate: "",
  isMachineWithRemarkVerified: false,
  selectedMachinesWithRemark: [],
};

const CreateUpdatePage = (props: any) => {
  const {
    refreshBinary,
    refreshConfig,
    refreshScript,
    refreshRsc,
    getBinaries,
    getConfigVersions,
    getRscFiles,
    getScripts,
  } = props;

  const resetDate = React.useRef<any>(null);
  const resetBinaryCommit = useRef<any>(null);
  const [configSelected, setConfigSelected] = useState<any>(false);
  const [uploadScriptFile, setUploadScriptFile] = useState<any[]>([]);
  const [uploadRscFile, setUploadRscFile] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [otaMachines, setOtaMachines] = useState<any[]>([]);

  const formRef = useRef<any>();
  // eslint-disable-next-line
  const [isMachineRemarksVerified = false, setMachineRemarksVerified] =
    useState<boolean>();
  const [
    showMachinesVerificationPopup = false,
    setShowMachinesVerificationPopup,
  ] = useState<boolean>();
  const [machinesWithRemark, setMachinesWithRemark] = useState([]);
  const [filteredMachines, setFilteredMachines] = useState([]);
  const [selectedMachinesWithRemark, setSelectedMachinesWithRemark] = useState<any[]>([]);
  const [searchMachine, setSearchMachine] = React.useState("");
  const [isCheckAll, setIsCheckAll] = useState(false);

  const onUploadFile = (e: any) => {
    let updateFiles = [];
    let id = e.target.id;
    for (let i = 0; i < e.target.files.length; i++) {
      updateFiles.push(e.target.files[i]);
      let reader = new FileReader();
      let file = e.target.files[i];
      if (id === "rsc_file") {
        reader.onloadend = () => {
          setUploadRscFile((preValue) => {
            return [
              ...preValue,
              {
                name: e.target.files[i].name,
                content: reader.result,
              },
            ];
          });
        };
        if (e.target.files[i]) {
          reader.readAsDataURL(file);
        }
      }
      if (id === "updateScript_file") {
        reader.onloadend = () => {
          setUploadScriptFile((preValue) => {
            return [
              ...preValue,
              {
                name: e.target.files[i].name,
                content: reader.result,
              },
            ];
          });
        };
        if (e.target.files[i]) {
          reader.readAsDataURL(file);
        }
      }
    }
  };

  const DeleteSelectFile = (content: any) => {
    setUploadRscFile(uploadRscFile.filter((e: any) => content !== e.content));
    setUploadScriptFile(
      uploadScriptFile.filter((e: any) => content !== e.content)
    );
  };

  const closeMachinesVerificationPopup = () => {
    setMachineRemarksVerified(false);
    setShowMachinesVerificationPopup(false);
  };
  const binariesRecord = useLazyLoadQuery<CreateUpdate_getBinariesQuery>(
    getBinaries,
    {}
  );
  const binariesResult: any = binariesRecord?.getBinaries;

  const configVersionRecord =
    useLazyLoadQuery<CreateUpdate_getConfigVersionQuery>(getConfigVersions, {});
  const configVersionResult: any = configVersionRecord?.getConfigVersion;

  const selectScriptRecord = useLazyLoadQuery<CreateUpdate_getScriptsQuery>(
    getScripts,
    {}
  );
  const selectScriptResult: any = selectScriptRecord?.getScripts;

  const rscRecord = useLazyLoadQuery<CreateUpdate_getRscQuery>(getRscFiles, {});
  const rscFileResult: any = rscRecord?.getRsc;

  const machinesRecord = useLazyLoadQuery<CreateUpdatePage_getOtaMachinesQuery>(
    getMachines,
    {}
  );
  let machinesResult: any = machinesRecord?.getOtaMachines;

  useEffect(() => {

    if (searchMachine !== "") {
      const tempName = searchMachine.toLowerCase();
      machinesResult = machinesResult.filter(
        (a: any) =>
          a.serialNumber.includes(tempName) ||
          a.name?.toLowerCase().includes(tempName) ||
          a.location?.name?.toLowerCase().includes(tempName)
      );
    }
    setFilteredMachines(machinesResult);
  }, [searchMachine]);

  return (
    <div>
      <Suspense>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Create Update
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark  fs-5">
                  Create Update
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="card mx-xl-4 mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <Formik
              innerRef={formRef}
              initialValues={createUpdateValues}
              validationSchema={validationSchema}
              onSubmit={async (values, { resetForm, setSubmitting }) => {
                let hasErrors = false;
                if (values.rscFile === "UPLOAD") {
                  if (uploadRscFile.length === 0) {
                    hasErrors = true;
                    toast.error("Please upload RSC file !");
                  }
                }
                if (values.scriptFile === "UPLOAD") {
                  if (uploadScriptFile.length === 0) {
                    hasErrors = true;
                    toast.error("Please upload update script file !");
                  }
                }
                if (hasErrors) {
                  return false;
                }
                setLoading(true);

                let hasRemark = false;
                values.otaMachines.forEach((otaMachine: any) => {
                  if (otaMachine?.remark !== "") {
                    hasRemark = true;
                  }
                });
                if (
                  hasRemark &&
                  !formRef.current.initialValues.isMachineWithRemarkVerified
                ) {
                  setShowMachinesVerificationPopup(true);
                  setMachinesWithRemark(values.otaMachines);
                  return;
                }

                let selectedMachines: any[] = values.otaMachines.filter(
                  (machine: any) => machine.remark === ""
                );

                if (
                  hasRemark &&
                  formRef.current.initialValues.isMachineWithRemarkVerified &&
                  selectedMachinesWithRemark.length === 0
                ) {
                  toast.error("You need to select atleast one machine");
                  formRef.current.initialValues.isMachineWithRemarkVerified =
                    false;

                  setMachineRemarksVerified(false);
                  setLoading(false);
                  return false;
                }

                if (
                  hasRemark &&
                  formRef.current.initialValues.isMachineWithRemarkVerified
                ) {
                  selectedMachines = [
                    ...selectedMachines,
                    ...selectedMachinesWithRemark,
                  ];
                }
                const otaMachines = selectedMachines.map(
                  (value: any) => value.id
                );
                const rscFiles = values.selectRscFile.map(
                  (value: any) => value.value
                );
                const $inputData: any = {
                  machines: otaMachines,
                  versionNo: values.versionNo,
                  binaryCommitPath: values.binaryCommit,
                  configVersionPath: values.configVersion,
                  launchDateAndTime: Moment(values.launchDate)
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss"),
                  status: "IN_QUEUE",
                };
                if (values.rscFile) {
                  $inputData["selectRscFileType"] = values.rscFile;
                }
                if (values.scriptFile) {
                  $inputData["selectUpdateScriptType"] = values.scriptFile;
                }
                if (values.rscFile === "SELECT") {
                  $inputData["selectRscFile"] = rscFiles;
                }
                if (values.scriptFile === "SELECT") {
                  $inputData["selectUpdateScript"] = [values.selectScriptFile];
                }
                if (values.scriptFile === "UPLOAD") {
                  $inputData["uploadUpdateScript"] = uploadScriptFile;
                }
                if (values.rscFile === "UPLOAD") {
                  $inputData["uploadRscFile"] = uploadRscFile;
                }
                const result = await createRelease($inputData);
                formRef.current.initialValues.isMachineWithRemarkVerified =
                  false;
                if (result?.data?.data?.createRelease) {
                  toast.success("Release created successfully");
                  setLoading(false);
                  setUploadRscFile([]);
                  setUploadScriptFile([]);
                  setConfigSelected(false);
                  setIsCheckAll(false)
                  resetBinaryCommit.current.setValue("");
                  resetDate.current.state.inputValue = "";
                  $("input[type=checkbox]").prop("checked", function () {
                    $(this).prop("checked", false);
                  });
                  resetForm();
                } else {
                  setSubmitting(false);
                  setLoading(false);
                  const messages = result?.data?.errors.map(
                    (e: any) => e.message
                  );
                  toast.error(messages.join(","));
                }
              }}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
              }) => (
                <div>
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="row">
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label mt-2 fs-4 required fw-bold">
                          Choose Machines
                        </label>

                        <div className="dropdown">
                          <input
                            type="text"
                            className="text-transform form-control cursor-pointer"
                            id="multiSelectDropdown"
                            onChange={(event: any) => {
                              setSearchMachine(event.target.value);
                            }}
                            value={searchMachine}
                            data-bs-toggle="dropdown"
                            placeholder="Search Machines"
                          />
                          {values.otaMachines.length > 0 && (
                            <>
                              {values.otaMachines.length === filteredMachines.length ?
                                <div
                                  className="text-transform form-control mt-2"
                                ><span>Selected All</span></div> :
                                <div
                                  className="form-control mt-2 px-2 p-1"
                                ><div>
                                    {values.otaMachines.map((value: any) => {
                                      let machineLabel = '';
                                      if (value?.name) {
                                        machineLabel = `${value?.name} (${value?.serialNumber})`;
                                      } else if (value?.location?.name) {
                                        machineLabel = `${value?.location?.name} (${value?.serialNumber})`;
                                      } else {
                                        machineLabel = `(${value?.serialNumber})`;
                                      }
                                      return (
                                        <div key={`machines-${value.id}`} className="bg-light me-1 px-1 d-inline-flex my-1 fs-6">{machineLabel}</div>
                                      )
                                    }
                                    )}
                                  </div>
                                </div>}
                            </>
                          )
                          }
                          <ul
                            id="list"
                            className="dropdown-menu overflow-scroll w-100"
                            style={{ border: '1px solid #E4E6EF', maxHeight: "300px" }}
                            aria-labelledby="multiSelectDropdown"
                          >
                            {filteredMachines.length > 0 &&
                              <li>
                                <label className="cursor-pointer form-check form-check-sm form-check-custom form-check-solid align-items-center p-1 py-2 hover-bg-light">
                                  <input
                                    className="form-check-input ms-2 fs-6"
                                    type="checkbox"
                                    name="selectAll"
                                    id="selectAll"
                                    checked={isCheckAll}
                                    onChange={() => {
                                      setIsCheckAll(!isCheckAll);
                                      setFieldValue("otaMachines", filteredMachines.map((li: any) => li));
                                      setOtaMachines(filteredMachines.map((li: any) => li.id))
                                      if (isCheckAll) {
                                        setFieldValue("otaMachines", []);
                                        setOtaMachines([])
                                      }
                                    }}
                                  />
                                  <span className="ms-2 fs-5 form-check-label">
                                    Select All
                                  </span>
                                </label>
                              </li>}
                            {filteredMachines.length > 0 ? (
                              <>
                                {filteredMachines.map((value: any, index: any) => {
                                  let machineLabel = '';
                                  if (value?.name) {
                                    machineLabel = `${value?.name} (${value?.serialNumber})`;
                                  } else if (value?.location?.name) {
                                    machineLabel = `${value?.location?.name} (${value?.serialNumber})`;
                                  } else {
                                    machineLabel = `(${value?.serialNumber})`;
                                  }
                                  return (
                                      <li key={`filtered-machines-${value.id}`}>
                                        <label key={index} className="cursor-pointer form-check form-check-sm form-check-custom form-check-solid align-items-center p-1 py-2 hover-bg-light">
                                          <input
                                            className="form-check-input ms-2 fs-6 "
                                            type="checkbox"
                                            id="otaMachines"
                                            value={value}
                                            name={value.location?.name ?? value.serialNumber}
                                            checked={
                                              values.otaMachines.includes(
                                                value
                                              )}
                                            onChange={(event: any) => {
                                              const { checked, name } = event.target;
                                              if (checked === true) {
                                                setFieldValue("otaMachines", [
                                                  ...values.otaMachines,
                                                  value,
                                                ]);
                                                const machines = [
                                                  ...otaMachines,
                                                  event.target.name,
                                                ];
                                                setOtaMachines(machines);
                                              } else {
                                                setFieldValue(
                                                  "otaMachines",
                                                  values.otaMachines.filter(
                                                    (e: any) => e !== value
                                                  )
                                                );
                                                const machines = [
                                                  values.otaMachines.filter(
                                                    (e: any) =>
                                                      e !== event.target.name
                                                  ),
                                                ];
                                                setIsCheckAll(false);
                                                setOtaMachines(machines);
                                              }
                                            }}
                                          />
                                          <span className="ms-2 fs-5 form-check-label">
                                            {machineLabel}
                                          </span>
                                        </label>
                                      </li>
                                    
                                  );
                                })}</>) :
                              <li className="text-center fs-5 fw-bold pt-3 text-muted">
                                No options
                              </li>
                            }
                          </ul>
                        </div>

                        <div className="text-danger text-align-top-center mt-1 ms-1">
                          <ErrorMessage name="otaMachines" />
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label mt-2 fs-4 required fw-bold">
                          Version No
                        </label>
                        <input
                          className={clsx("form-control", {
                            "is-invalid":
                              Boolean(errors.versionNo) && touched.versionNo,
                          })}
                          name="versionNo"
                          id="versionNo"
                          value={values.versionNo}
                          onChange={handleChange}
                        />
                        <div className="text-danger text-align-top-center mt-1 ms-1">
                          <ErrorMessage name="versionNo" />
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label mt-2 fs-4 fw-bold">
                          Choose Binary Commit
                          <div
                            onClick={() => refreshBinary()}
                            className="btn btn-transparent px-1 ms-3 py-0 text-center justify-content-center align-items-center"
                          >
                            <i className="fa fa-refresh fs-4 mb-1 spin"></i>
                          </div>
                        </label>
                        <Select
                          ref={resetBinaryCommit}
                          classNamePrefix="my-react-select"
                          className={clsx("react-select", {
                            "is-invalid":
                              Boolean(errors.binaryCommit) &&
                              touched.binaryCommit,
                          })}
                          name="binaryCommit"
                          onChange={(e: any) => {
                            setFieldValue("binaryCommit", e.value);
                          }}
                          placeholder=""
                          options={Array.from(binariesResult).map(
                            (value: any) => {
                              return {
                                value: value.url,
                                label: value.fileName,
                              };
                            }
                          )}
                        />
                        <div className="text-danger text-align-top-center mt-1 ms-1">
                          <ErrorMessage name="binaryCommit" />
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label mt-2 fs-4 fw-bold">
                          Choose Config Version
                          <div
                            className="btn btn-transparent px-1 ms-3 py-0 text-center justify-content-center align-items-center"
                            onClick={() => refreshConfig()}
                          >
                            <i className="fa fa-refresh fs-4 mb-1 spin"></i>
                          </div>
                        </label>
                        <Select
                          className="react-select"
                          classNamePrefix="my-react-select"
                          placeholder=""
                          isMulti
                          name="configVersion"
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          value={configSelected}
                          onChange={(selected: any, e: any) => {
                            const value = selected?.map((e: any) => e.value);
                            setConfigSelected([...selected]);
                            setFieldValue("configVersion", value);
                          }}
                          components={{
                            Option,
                          }}
                          options={Array.from(configVersionResult).map(
                            (value: any) => {
                              return {
                                value: value.url,
                                label: value.fileName,
                              };
                            }
                          )}
                        />
                        <div className="text-danger text-align-top-center mt-1 ms-1">
                          <ErrorMessage name="configVersion" />
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label mt-2 fs-4 required fw-bold">
                          Launch Date
                        </label>
                        <Datetime
                          ref={resetDate}
                          className={clsx("date-control", {
                            "is-invalid":
                              Boolean(errors.launchDate) && touched.launchDate,
                          })}
                          dateFormat="YYYY-MM-DD"
                          timeFormat="HH:mm"
                          closeOnSelect
                          isValidDate={(current) => {
                            let customDate = Moment().format("YYYY-MM-DD");
                            return (
                              current &&
                              current >= Moment(customDate, "YYYY-MM-DD")
                            );
                          }}
                          value={values.launchDate}
                          onChange={(e: any) => {
                            const dt = e["_d"];
                            setFieldValue("launchDate", dt);
                          }}
                        />
                        <div className="text-danger text-align-top-center mt-1 ms-1">
                          <ErrorMessage name="launchDate" />
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label required mt-2 ms-2 fs-4 fw-bold">
                          Update Script Type
                        </label>
                        <div
                          className="btn btn-transparent px-1 ms-3 py-0 text-center justify-content-center align-items-center"
                          onClick={() => refreshScript()}
                        >
                          <i className="fa fa-refresh fs-4 mb-1 spin"></i>
                        </div>
                        <div className="d-flex mb-3 mt-2">
                          <label className="form-check form-check-custom form-check-solid form-check-sm mt-1 ps-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="upload"
                              name="scriptFile"
                              value={values.scriptFile}
                              checked={values.scriptFile === "UPLOAD"}
                              onChange={() =>
                                setFieldValue("scriptFile", "UPLOAD")
                              }
                            />
                            <span className="form-check-label fs-4 mb-0 ms-2">
                              Upload
                            </span>
                          </label>
                          <label className="form-check form-check-custom form-check-solid form-check-sm mt-1 ps-4">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="select"
                              name="scriptFile"
                              checked={values.scriptFile === "SELECT"}
                              value={values.scriptFile}
                              onChange={() =>
                                setFieldValue("scriptFile", "SELECT")
                              }
                            />
                            <span className="form-check-label fs-4 mb-0 ms-2">
                              Select
                            </span>
                          </label>
                        </div>
                        {values.scriptFile === "UPLOAD" ? (
                          <>
                            <label htmlFor="updateScript_file" className="fs-4">
                              <input
                                type="file"
                                id="updateScript_file"
                                onChange={onUploadFile}
                                accept="text/plain"
                                placeholder="Upload update script"
                                className="d-none"
                                multiple
                              />
                              <span
                                className="btn btn-bg-light text-gray-700 fs-4 w-100 mt-2 ms-2 fs-5 px-5 py-3 w-100"
                                style={{ cursor: "pointer" }}
                              >
                                Upload Update Script
                              </span>
                            </label>
                            {uploadScriptFile.map((data: any) => {
                              const { name, content } = data;
                              return (
                                <span className="d-flex align-items-center me-2 mt-3" key={`script-files-${name}`}>
                                  <span className="symbol symbol-50px me-6">
                                    <span className="symbol-label">
                                      <i className="far fa-file-alt fs-1 text-gray-700"></i>
                                    </span>
                                  </span>

                                  <span className="d-flex flex-column">
                                    <span className="fw-bold text-gray-800 fs-5">
                                      {name}
                                    </span>
                                    <span
                                      className="file-link text-muted text-hover-gray-700 fw-semibold"
                                      onClick={() => DeleteSelectFile(content)}
                                    >
                                      Delete
                                    </span>
                                  </span>
                                </span>
                              );
                            })}
                          </>
                        ) : (
                          ""
                        )}
                        {values.scriptFile === "SELECT" ? (
                          <div className="container p-2 border overflow-y-auto rounded">
                            <div
                              className="overflow-auto list-container fs-5"
                              style={{
                                maxWidth: "400px",
                                maxHeight: "100px",
                              }}
                            >
                              {selectScriptResult.length > 0 ? (
                                selectScriptResult.map(
                                  (value: any, index: any) => {
                                    return (
                                      <label
                                        key={index}
                                        className="form-check form-check-custom form-check-solid form-check-sm my-2 d-flex align-items-center fs-6"
                                      >
                                        <input
                                          className="form-check-input ms-2"
                                          type="radio"
                                          name="selectScriptFile"
                                          value={value.url}
                                          checked={
                                            values.selectScriptFile ===
                                            value.url
                                          }
                                          onChange={() =>
                                            setFieldValue(
                                              "selectScriptFile",
                                              value.url
                                            )
                                          }
                                        />
                                        <span
                                          className="form-check-label ms-2 fs-6"
                                          style={{ cursor: "pointer" }}
                                        >
                                          {value.fileName}
                                        </span>
                                      </label>
                                    );
                                  }
                                )
                              ) : (
                                <div className="text-muted text-center">
                                  No options
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="text-danger text-align-top-center mt-1 ms-1">
                          <ErrorMessage name="scriptFile" />
                        </div>
                        <div className="text-danger text-align-top-center mt-1 ms-1">
                          <ErrorMessage name="selectScriptFile" />
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label mt-2 ms-2 fs-4 fw-bold">
                          RSC File Type
                          <div
                            className="btn btn-transparent px-1 ms-3 py-0 text-center justify-content-center align-items-center"
                            onClick={() => refreshRsc()}
                          >
                            <i className="fa fa-refresh fs-4 mb-1 spin"></i>
                          </div>
                        </label>
                        <div className="d-flex mb-3 mt-2">
                          <label className="form-check form-check-custom form-check-solid form-check-sm mt-1 ps-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="upload"
                              name="rscFile"
                              checked={values.rscFile === "UPLOAD"}
                              value={values.rscFile}
                              onChange={() =>
                                setFieldValue("rscFile", "UPLOAD")
                              }
                            />
                            <span className="form-check-label fs-4 mb-0 ms-2">
                              Upload
                            </span>
                          </label>
                          <label className="form-check form-check-custom form-check-solid form-check-sm mt-1 ps-4">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="select"
                              name="rscFile"
                              checked={values.rscFile === "SELECT"}
                              value={values.rscFile}
                              onChange={() =>
                                setFieldValue("rscFile", "SELECT")
                              }
                            />
                            <span className="form-check-label fs-4 mb-0 ms-2">
                              Select
                            </span>
                          </label>
                        </div>
                        {values.rscFile === "UPLOAD" ? (
                          <>
                            <label htmlFor="rsc_file" className="fs-4">
                              <input
                                type="file"
                                id="rsc_file"
                                onChange={onUploadFile}
                                placeholder="Add rsc file"
                                className="d-none"
                                hidden
                                multiple
                              />
                              <span
                                className="btn btn-bg-light text-gray-700 fs-4 w-100 mt-2 ms-2 fs-5 px-5 py-3"
                                style={{ cursor: "pointer" }}
                              >
                                Upload RSC file
                              </span>
                            </label>
                            {uploadRscFile.map((data: any) => {
                              const { name, content } = data;
                              return (
                                <span className="d-flex align-items-center me-2 mt-3" key={`rsc-files-${name}`}>
                                  <span className="symbol symbol-50px me-6">
                                    <span className="symbol-label">
                                      <i className="far fa-file-alt fs-1 text-gray-700"></i>
                                    </span>
                                  </span>

                                  <span className="d-flex flex-column">
                                    <span className="fw-bold text-gray-800 fs-5">
                                      {name}
                                    </span>
                                    <span
                                      className="file-link text-muted text-hover-gray-700 fw-semibold"
                                      onClick={() => DeleteSelectFile(content)}
                                    >
                                      Delete
                                    </span>
                                  </span>
                                </span>
                              );
                            })}
                          </>
                        ) : (
                          ""
                        )}
                        {values.rscFile === "SELECT" ? (
                          <>
                            <MultiSelect
                              class={clsx("react-select", {
                                "is-invalid":
                                  Boolean(errors.selectRscFile) &&
                                  touched.selectRscFile,
                              })}
                              title=""
                              setSelected={(selected) => {
                                setFieldValue("selectRscFile", selected);
                              }}
                              selected={values.selectRscFile}
                              options={Array.from(rscFileResult).map(
                                (value: any) => {
                                  return {
                                    value: value.url,
                                    label: value.fileName,
                                  };
                                }
                              )}
                            />
                          </>
                        ) : (
                          ""
                        )}
                        <div className="text-danger text-align-top-center mt-1 ms-1">
                          <ErrorMessage name="rscFile" />
                        </div>
                        <div className="text-danger text-align-top-center mt-1 ms-1">
                          <ErrorMessage name="selectRscFile" />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4 col-12 px-xl-5 p-2">
                          <button
                            className="btn btn-primary fs-4 col-6 mt-3 w-100"
                            type="submit"
                          >
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">
                                CREATE UPDATE
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div >

        <Modal
          // id="edit"
          open={showMachinesVerificationPopup}
          onClose={closeMachinesVerificationPopup}
          role="modal"
          center
          classNames={{
            root: "content-wrapper",
            modal: "modal-lg",
          }}
        >
          <div className="modal-header p-2 pb-4 mb-4">
            <h4 className="modal-title fs-1">Verify machines remark</h4>
          </div>
          <div className="modal-body" style={{ minWidth: "800px" }}>
            <div className="row">
              <table
                className="table  table-bordered"
                style={{
                  width: "94%",
                  border: "1px solid #dee2e6  !important",
                }}
              >
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #dee2e6 !important" }}></th>
                    <th style={{ border: "1px solid #dee2e6  !important" }}>
                      Machine
                    </th>
                    <th style={{ border: "1px solid #dee2e6  !important" }}>
                      Remark
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {machinesWithRemark.map((machine: any, index) => {
                    return (
                      <>
                        <tr
                          key={"remark-" + { index }}
                          className={machine.remark === "" ? "hide" : ""}
                        >
                          <td
                            style={{
                              border: "1px solid #dee2e6;",
                              textAlign: "center",
                            }}
                          >
                            <label className="form-check form-check-sm form-check-custom form-check-solid align-items-center">
                              <input
                                className="form-check-input text-center align-items-center mx-auto"
                                type="checkbox"
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    const tempMachines = [
                                      ...selectedMachinesWithRemark,
                                      machine,
                                    ];
                                    setSelectedMachinesWithRemark(tempMachines);
                                  } else {
                                    const tempMachines =
                                      selectedMachinesWithRemark;
                                    const remainingMachines = tempMachines.filter(
                                      (tempM) => tempM.value !== machine.id
                                    );
                                    setSelectedMachinesWithRemark(
                                      remainingMachines
                                    );
                                  }
                                }}
                              />
                            </label>
                          </td>
                          <td style={{ border: "1px solid #dee2e6;" }}>
                            {machine?.name?.replace("*", "")}
                          </td>
                          <td style={{ border: "1px solid #dee2e6;" }}>
                            {machine.remark}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer mt-6 p-0 justify-content-start">
            <button
              type="submit"
              onClick={() => {
                setMachineRemarksVerified(true);
                formRef.current.initialValues.isMachineWithRemarkVerified =
                  true;
                formRef.current.initialValues.selectedMachinesWithRemark =
                  selectedMachinesWithRemark;
                formRef.current.handleSubmit();
                setShowMachinesVerificationPopup(false);
                return;
              }}
              className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
            >
              <span className="indicator-label">Submit</span>
            </button>
          </div>
        </Modal>

        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </Suspense >
    </div >
  );
};

export default CreateUpdatePage;
