import { ActionMeta, Options } from "react-select";
import { Dispatch, SetStateAction, useRef } from "react";
import Select, { components } from "react-select";

interface Props {
  options: Option[];
  selected: Option[];
  setSelected: Dispatch<SetStateAction<any>>;
  title: string;
  class: string;
  hide?: boolean;
}

export type Option = {
  value: number | string;
  label: string;
};

export default function MultiSelect(props: Props) {
  const valueRef = useRef(props.selected);
  valueRef.current = props.selected;

  const selectAllOption = { value: "*", label: "Select All" };
  const isSelectAllSelected = () =>
    valueRef.current.length === props.options.length &&
    props.options.length > 1;
  const isOptionSelected = (option: Option, selectValue: Options<Option>) =>
    valueRef.current.some(({ value }) => value === option.value) ||
    isSelectAllSelected();

  const getOptions = () =>
    props.options.length > 1
      ? [selectAllOption, ...props.options]
      : [...props.options];

  const Option = (props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <label className="form-check form-check-sm form-check-custom form-check-solid align-items-center">
            <input
              className="form-check-input ms-2"
              type="checkbox"
              readOnly
              checked={props.isSelected}
            />
            <span className="ms-2 fs-5 form-check-label">{props.label}</span>
          </label>
        </components.Option>
      </div>
    );
  };

  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption] : props.selected;

  const handleSelect = (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
    const { action, option, removedValue } = actionMeta;
    const opt = option as Option;
    const removed = removedValue as Option;
    if (action === "select-option" && opt.value === selectAllOption.value) {
      props.setSelected(props.options);
    } else if (
      (action === "deselect-option" && opt.value === selectAllOption.value) ||
      (action === "remove-value" && removed.value === selectAllOption.value)
    ) {
      props.setSelected([]);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      props.setSelected(
        props.options.filter(({ value }) => value !== opt.value)
      );
    } else {
      props.setSelected(newValue || []);
    }
  };

  return (
    <Select
      isOptionSelected={isOptionSelected}
      classNamePrefix="my-react-select"
      className={props.class}
      closeMenuOnSelect={false}
      defaultValue={getOptions()}
      value={getValue()}
      isMulti
      isSearchable
      components={{
        Option,
      }}
      placeholder=""
      options={getOptions()}
      onChange={handleSelect}
      hideSelectedOptions={props.hide ?? false}
      instanceId={props.title}
      id={props.title}
    />
  );
}
