import { Environment, Network, RecordSource, RequestParameters, Store, Variables } from "relay-runtime";
import { AUTH_LOCAL_STORAGE_KEY } from "./app/modules/auth";

async function fetchGraphQL(params: RequestParameters, variables: Variables) {
  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  let authToken = "";
  if (lsValue) {
    const user = JSON.parse(lsValue);
    authToken = user.access_token;
  }
  const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${authToken}`,
    },
    body: JSON.stringify({
      query: params.text,
      variables,
    }),
  });

  return await response.json();
}

// eslint-disable-next-line
export default new Environment({
  network: Network.create(fetchGraphQL),
  store: new Store(new RecordSource()),
});