import { useIntl } from "react-intl";
import { MenuItem } from "./MenuItem";

export function MenuInner() {
  const intl = useIntl();
  return (
    <>
      <MenuItem
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        to="/dashboard"
      />
      {/* <MenuItem title='Layout Builder' to='/builder' /> */}
      {/* <MenuInnerWithSub
        title='Pages'
        to='/'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuInnerWithSub
          title='create Update'
          to='/update/create'
          fontIcon='bi-archive'
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
        </MenuInnerWithSub>

        <MenuInnerWithSub
          title='view Updates'
          to='/update/view'
          fontIcon='bi-archive'
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
        </MenuInnerWithSub>
      </MenuInnerWithSub> */}
    </>
  );
}
