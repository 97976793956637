import React from "react";
import Iframe from "react-iframe";
import { toAbsoluteUrl } from "../../_metronic/helpers";

function Manual() {
  return (
    <div>
      <Iframe
        url={`${toAbsoluteUrl("/Document/document.pdf")}`}
        height="750px"
        className="w-100"
        display="block"
        position="relative"
      />
    </div>
  );
}

export default Manual;
