import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { useThemeMode } from "../../../_metronic/partials";

const BODY_CLASSES = [
  "bgi-size-cover",
  "bgi-position-center",
  "bgi-no-repeat",
  "bg-overlay",
];
const Error404: FC = () => {
  const { mode } = useThemeMode();
  useEffect(() => {
    BODY_CLASSES.forEach((c) => document.body.classList.add(c));
    document.body.style.backgroundImage =
      mode === "dark"
        ? `url(${toAbsoluteUrl("/media/auth/bg.webp")})`
        : `url(${toAbsoluteUrl("/media/auth/bg-2.webp")})`;

    return () => {
      BODY_CLASSES.forEach((c) => document.body.classList.remove(c));
      document.body.style.backgroundImage = "none";
    };
  }, [mode]);
  return (
    <>
      <div className="d-flex flex-column flex-root h-100">
        <div className="d-flex flex-column flex-center flex-column-fluid">
          <div className="d-flex flex-column flex-center text-center p-10">
            <div className="card card-flush w-md-450px w-lg-650px py-5">
              <div className="card-body py-15 py-lg-20">
                <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">
                Oops!
                </h1>

                <div className="fw-semibold fs-6 text-gray-500 mb-7">
                  We can't find that page.
                </div>

                <div className="mb-3">
                  <img
                      src={toAbsoluteUrl('/media/auth/404.png')}
                      className='mw-100 mh-300px'
                      alt=''
                    />
                </div>

                <div className="mb-0">
                  <Link to="/dashboard" className="btn btn-sm btn-primary">
                    Return Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Error404 };
