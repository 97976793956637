import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function getReleases(where: {} = {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
              query getReleases(  
                $where:OtaUpdateResolver_GetReleases_FilterInputType
                ) {
                  getReleases(where: $where) {
                  id
                  versionNo
                  binaryCommitPath
                  configVersionPath
                  updateScriptPath
                  rscFilePath
                  launchDateAndTime
                  bundlePath
                  createdAt
                  cancelledById
                  createdById
                  updatedById
                  cancelReason
                  cancelledAt
                  status
                  machines {
                    id
                    releaseId
                    machineId
                    status
                    deployedDateAndTime
                    launchDateAndTime
                    createdAt
                    machineDetail{
                      serialNumber
                      uuid
                      name
                      deploymentDate
                      deployingPerson{
                        firstName
                      }
                      deploymentType{
                        name
                      }
                      location{
                        id
                        name
                      }
                      locationHistory{
                        id
                        location{
                          name
                          address        
                        }
                      }
                    }
                  }
                  createdBy {
                    id
                    firstName
                    lastName
                  }
                  updatedBy {
                    id
                    firstName
                    lastName
                  }
                  cancelledBy {
                    id
                    firstName
                    lastName
                  }
                }
              }
              `,
      variables: { where },
    },
  });
  return data?.data?.data?.getReleases;
}

export async function abortRelease(
  releaseId: string,
  status: string,
  cancelReason?: string
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation abortRelease (
                $releaseId: String!
                $status: ReleaseStatus!
                $cancelReason: String!
              ){
            abortRelease(
              abortRelease: {
                releaseId: $releaseId
                status: $status
                cancelReason: $cancelReason
              }
            ) 
          }
        `,
      variables: {
        releaseId,
        status,
        cancelReason,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function abortReleaseMachine(
  machineReleaseId: string,
  status: string
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation abortReleaseMachine (
                $machineReleaseId: String!
                $status: ReleaseStatus!
              ){
            abortReleaseMachine(
              abortReleaseMachine: {
                machineReleaseId: $machineReleaseId
                status: $status
              }
            ) 
          }
        `,
      variables: {
        machineReleaseId,
        status,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getOtaMachines() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query {
          getOtaMachines {
            id
            name
            remark
            serialNumber
            location {
              id
              name
            }
          }
        }
      `,
      variables: {},
    },
  });
  return data?.data?.data?.getOtaMachines;
}
