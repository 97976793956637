import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateReleaseInput {
  machines: string[];
  versionNo: string;
  binaryCommitPath: string;
  configVersionPath: string;
  launchDateAndTime: string;
  status: string;

  selectUpdateScriptType?: string;
  selectUpdateScript?: string[];
  uploadUpdateScript?: string[];

  selectRscFileType?: string;
  selectRscFile?: string[];
  uploadRscFile?: string[];
}
export async function createRelease(input: CreateReleaseInput) {
  let data: any;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
      mutation createRelease (
        $input: CreateReleaseInput!
        ){
        createRelease(
            createReleaseInput: $input
          ) 
      }
      `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

