import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`;
export const LOGIN_URL = `${API_URL}/login`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;

// Server should return AuthModel
export async function login(email: string, password: string) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            mutation developerLogin ( $email: String!, $password: String! ){
              developerLogin(developerLoginInput: { email: $email, password: $password }) {
          access_token
          user {
            id
            firstName
            lastName
            email
            roleId
            slackId
            resetPasswordKey
            createdAt
            updatedAt
           
            isDeveloper
            
          
          }
        }
      }
      `,
      variables: {
        email,
        password,
      },
    },
  });

  return data;
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

// export function getUserByToken(token: string) {
//   return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
//     api_token: token,
//   })
// }
export async function getUserByToken(token: string) {
  return axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
      query {
        getMe {
          id
          firstName
          lastName
          email
          roleId
          slackId
          resetPasswordKey
          createdAt
          updatedAt
          role {
            id
            name
            slug
            createdAt
            updatedAt
            permissions {
              id
              name
              slug
            }
          }
        }
      }
      `,
      variables: {},
    },
  });
}
