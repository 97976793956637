import React, { Component, createRef } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Select from "react-select";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { abortRelease, abortReleaseMachine,getOtaMachines, getReleases } from "../models/_viewUpdate";
import { KTSVG } from "../../_metronic/helpers";
import toast, { Toaster } from "react-hot-toast";
import _ from "lodash";
import Moment from "moment";
import ReactSelect from "react-select";
class ViewUpdate extends Component<any, any> {
  readonly props: any;
  constructor(props: any) {
    super(props);
    this.state = {
      resetStatus: createRef(),
      resetFrom: createRef(),
      resetTo: createRef(),
      viewUpdateList: [],
      addLoading: false,
      filterLoading: false,
      abortOpen: false,
      loading: false,
      currentlyOpenRecord: {},
      filterFromDate: "",
      filterToDate: "",
      abortMachineOpen: "",
      cancelReason: "",
      machinesDB: [],
      status: "",
      cancelStatus: false,
      readyReleaseStatus: false,
      machineStatus: "",
      filterStatus: "",
      filterMachine: [],
      machineSelected: null,
      statusDB: [
        {
          id: "IN_QUEUE",
          name: "In Queue",
        },
        {
          id: "READY_FOR_RELEASE",
          name: "Ready For Release",
        },
        {
          id: "IN_PROCESS",
          name: "In Process",
        },
        {
          id: "PARTIALLY_RELEASED",
          name: "Partially Released",
        },
        {
          id: "CANCELLED",
          name: "Cancelled",
        },
        {
          id: "COMPLETED",
          name: "Completed",
        },
      ],
    };
  }

  async componentWillMount() { }

  async componentDidMount() {
    var _ = this;
    $("#updateTable").on("click", ".abortBtn", function () {
      const record: any = $(this).attr("data-recordId");
      _.abortOpen(record);
    });
    $("#updateTable").on("click", ".abortMachineBtn", function () {
      const record: any = $(this).attr("data-recordId");
      _.abortMachineOpen(record);
    });
    await this.getFilterRecords();
    const rows: any = await this.getData();
    this.renderDataTable(rows);
  }

  async componentDidUpdate() { }

  // rendering datatable jquery
  renderDataTable = (rows: any) => {
    var _this = this;
    $(document).ready(function () {
      $("#updateTable").DataTable().destroy();

      let table = $("#updateTable").DataTable({
        data: rows,
        columns: [
          {
            className: "details-control",
            orderable: false,
            defaultContent: "&nbsp;&nbsp;&nbsp;",
          },
          {
            name: "createdAt",
            render: function (data, type, row) {
              return row.createdAt;
            },
          },
          {
            name: "softwareInfo",
            render: function (data, type, row) {
              return row.softwareInfo;
            },
          },
          {
            name: "launchDate",
            render: function (data, type, row) {
              return row.launchDate;
            },
          },
          {
            name: "createdBy",
            render: function (data, type, row) {
              return row.createdBy;
            },
          },
          {
            name: "status",
            render: function (data, type, row) {
              return _.capitalize(_.lowerCase(row.status));
            },
          },
          {
            name: "action",
            render: function (data, type, row) {
              let returnData = "";
              if (row.status !== "CANCELLED" && row.status !== "COMPLETED") {
                returnData +=
                  '<button class="btn btn-secondary btn abortBtn m-1 p-2 px-4" data-bs-toggle="tooltip" title="Click here to abort release" data-recordId=\'' +
                  row.recordId +
                  "'>Abort</button>";
              }
              return returnData;
            },
          },
        ],
        pagingType: "full_numbers",
        pageLength: 20,
        processing: true,
        dom: "Bfrtip",
        destroy: true,
        order: [[1, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
        createdRow: function (row, data, rowIndex) {
          var title: any = [];
          $("#updateTable thead tr th").each(function () {
            title.push($(this).text());
          });
          $.each($("td", row), function (colIndex) {
            $(this).attr("data-title", title[colIndex]);
          });
        },
      });

      $(".dt-buttons").remove();
      const format1 = (row: any) => {
        let tabla = `<table class="sub-row" cellpadding="5" cellspacing="0" style="border-collapse: separate;">
              <thead style="border-top: none !important">
                <tr>
                    <th></th>
                    <th>
                       Id
                    </th>
                    <th>
                      Serial Number
                    </th>
                    <th>
                      Machine Name
                    </th>
                    <th>
                      Status
                    </th>
                    <th>
                      Launch Date
                    </th>
                    <th>
                      Deployed Date
                    </th>
                    <th>
                      Action
                    </th>
                </tr>
              </thead>`;
        if (row?.record?.machines?.length > 0) {
          let counter = 0;
          const sortRecord = row.record.machines.sort(function (a: any, b: any) {
            if (a.machineDetail?.location?.name.toLowerCase() < b.machineDetail?.location?.name.toLowerCase()) {
              return -1;
            }
            if (a.machineDetail?.location?.name.toLowerCase() > b.machineDetail?.location?.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          for (const machine of sortRecord.filter((item: any) =>_this.state.filterMachine.length === 0 || _this.state.filterMachine.includes(item.machineId))) {
            counter++;
            let launchDate = "";
            if (machine.launchDateAndTime) {
              launchDate = Moment.unix(machine.launchDateAndTime / 1000)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
            }
            let deployedDate = "";
            if (machine.deployedDateAndTime) {
              deployedDate = Moment.unix(machine.deployedDateAndTime / 1000)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
            }
            let action = "";
            if (machine.status === "READY_FOR_RELEASE") {
              action +=
                '<button class="btn btn-secondary btn abortMachineBtn m-1 p-2 px-4" data-bs-toggle="tooltip" title="Click here to abort release" data-recordId=\'' +
                machine.id +
                "'>Abort</button>";
            }

            tabla += `<tbody>
                        <tr>
                        <td>${counter}</td>
                          <td data-label="Id">${machine.machineDetail?.uuid ?? ""
              }</td>
                          <td data-label="Serial Number">${machine.machineDetail?.serialNumber ?? ""
              }</td>
                          <td data-label="Machine">${machine.machineDetail?.location?.name ?? ""
              }</td>
                          <td data-label="Status">${_.capitalize(
                _.lowerCase(machine.status ?? "")
              )}</td>
                          <td data-label="Launch Date">${launchDate ?? ""}</td>
                          <td data-label="Deployment Date">${deployedDate ?? ""
              }</td>
                          <td data-label="Action">
                       ${action ?? ""}
                          </td>

                        </tr>
                      </tbody>`;
          }
        }
        tabla += `</table>`;
        return tabla;
      };

      $("#updateTable tbody").on("click", "td.details-control", function () {
        var tr = $(this).parents("tr");
        var rows = table.row(tr);
        if (rows.child.isShown()) {
          rows.child.hide();
          tr.removeClass("shown");
        } else {
          rows.child(format1(rows.data())).show();
          tr.addClass("shown");
        }
      });
    });
  };

  async getFilterRecords() {
    const machineRecord = await getOtaMachines();
    const machineIdResults: any = machineRecord
      ? machineRecord
      : [];
      machineIdResults.sort(function (a: any, b: any) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    this.setState({
      machinesDB: machineRecord,
    });
  };
  // fetch data into datatable
  getData = async (isReset = false) => {
    let filter: any = {};

    if (this.state.filterStatus) {
      filter["status"] = { eq: this.state.filterStatus };
    }
    if (this.state.filterMachine?.length > 0) {
      filter["machine_id"] = { in: this.state.filterMachine };
    }
    if (this.state.filterFromDate !== "" || this.state.filterToDate !== "") {
      filter["launch_date_and_time"] = {};
    }
    if (this.state.filterFromDate !== "" && this.state.filterToDate === "") {
      filter["launch_date_and_time"]["gt"] = new Date(
        this.state.filterFromDate
      ).toISOString();
    } else if (
      this.state.filterFromDate === "" &&
      this.state.filterToDate !== ""
    ) {
      filter["launch_date_and_time"]["lt"] = new Date(
        this.state.filterToDate
      ).toISOString();
    } else if (
      this.state.filterFromDate !== "" &&
      this.state.filterToDate !== ""
    ) {
      filter["launch_date_and_time"]["between"] = [
        new Date(this.state.filterFromDate).toISOString(),
        new Date(this.state.filterToDate).toISOString(),
      ];
    }

    const records: any = await getReleases(filter);
    let counter = 0;
    const rows = [];
    const viewUpdateListObj: any = {};
    for (const record of records) {
      counter++;
      let launchDate = "";
      if (record.launchDateAndTime) {
        launchDate = Moment.unix(record.launchDateAndTime / 1000)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
      }

      const softwareInfo = `
      <div class="overflow-auto">
        ${`<span class="column-width"><strong> Release Id : </strong>${record.id}</span>`
        } 
   
      <span class="column-width"><strong>Software Version : </strong> ${record.versionNo
        }</span>
     
      ${record.binaryCommitPath
          ? `<span class="column-width"><strong>Binary File : </strong>${record.binaryCommitPath}</span>`
          : ""
        } 
      ${record.configVersionPath &&
          record.configVersionPath.length > 0 &&
          record.configVersionPath !== ""
          ? `<span class="column-width"><strong>Config Version : </strong>${record.configVersionPath}</span>`
          : ""
        }
      ${record.updateScriptPath.length > 0
          ? `<span class="column-width"><strong>Update Script : </strong>${record.updateScriptPath}</span>`
          : ""
        }
      ${record.rscFilePath.length > 0
          ? `<span class="column-width"><strong>RSC File : </strong>${record.rscFilePath}</span>`
          : ""
        }</div>`;

      const tempRecord = {
        id: counter,
        recordId: record.id,
        createdAt: Moment.utc(record.createdAt)
          .local()
          .format("YYYY-MM-DD HH:mm:ss"),
        launchDate: launchDate,
        softwareInfo: softwareInfo,
        versionNo: record.versionNo,
        createdBy: record.createdBy?.firstName ?? "",
        status: record.status,
        record: record,
      };
      const machines = record.machines ?? "";
      for (const machine of machines) {
        machine["record"] = { ...machine };
        viewUpdateListObj[machine.id] = machine;
      }

      rows.push(tempRecord);
      viewUpdateListObj[record.id] = tempRecord;
    }
    this.setState({
      viewUpdateListObj: viewUpdateListObj,
      viewUpdateList: rows,
    });
    return rows;
  };

  abortOpen = (recordId: any) => {
    const record = this.state.viewUpdateListObj[recordId];
    this.setState({
      abortOpen: true,
      status: record.record.status,
      currentlyOpenRecord: record.record,
    });
  };

  abortClose = () => {
    this.setState({
      abortOpen: false,
      status: "",
      cancelReason: "",
      cancelStatus: false,
      currentlyOpenRecord: {},
    });
  };

  submitUpdate = async (record: any) => {
    let hasErrors = false;
    if (!this.state.cancelStatus) {
      hasErrors = true;
      toast.error("Please select cancel release!");
    }
    if (this.state.cancelStatus === true) {
      if (this.state.cancelReason === "") {
        hasErrors = true;
        toast.error("Please enter reason!");
      }
    }
    if (hasErrors) {
      return false;
    }
    this.setState({
      loading: true,
    });
    const updateData = {
      id: record.id,
      status: this.state.status,
      cancelReason: this.state.cancelReason,
    };
    const result = await abortRelease(
      updateData.id,
      updateData.status,
      updateData.cancelReason
    );
    if (result?.data?.data?.abortRelease) {
      this.setState({
        loading: false,
      });
      toast.success("Release aborted successfully");
      this.abortClose();
      const rows = await this.getData();
      this.renderDataTable(rows);
    } else {
      this.setState({
        loading: false,
      });
      const messages = result?.data?.errors.map((e: any) => e.message);
      toast.error(messages.join(","));
    }
  };

  abortMachineOpen = (recordId: any) => {
    const record = this.state.viewUpdateListObj[recordId];

    this.setState({
      abortMachineOpen: true,
      machineStatus: record.record.status,
      currentlyOpenRecord: record.record,
    });
  };

  abortMachineClose = () => {
    this.setState({
      abortMachineOpen: false,
      machineStatus: "",
      readyReleaseStatus: false,
      currentlyOpenRecord: {},
    });
  };

  submitAbort = async (record: any) => {
    let hasErrors = false;
    if (!this.state.readyReleaseStatus) {
      hasErrors = true;
      toast.error("Please select cancel machine release!");
    }

    if (hasErrors) {
      return false;
    }
    this.setState({
      loading: true,
    });
    const updateData = {
      machineReleaseId: record.id,
      status: this.state.machineStatus
    };
    const result = await abortReleaseMachine(
      updateData.machineReleaseId,
      updateData.status,
    );
    if (result?.data?.data?.abortReleaseMachine) {
      this.setState({
        loading: false,
      });
      toast.success("Machine release aborted successfully");
      this.abortMachineClose();
      const rows = await this.getData();
      this.renderDataTable(rows);
    } else {
      this.setState({
        loading: false,
      });
      const messages = result?.data?.errors.map((e: any) => e.message);
      toast.error(messages.join(","));
    }
  };


  // filter data submit
  searchData = async () => {
    this.setState({
      addLoading: true,
    });
    const rows = await this.getData();
    this.renderDataTable(rows);
    this.setState({
      addLoading: false,
    });
    return false;
  };

  removeSearch = async () => {
    this.setState({
      filterLoading: true,
    });

    this.state.resetStatus.current.setValue("");
    // eslint-disable-next-line
    this.state.resetFrom.current.state.inputValue = "";
    // eslint-disable-next-line
    this.state.resetTo.current.state.inputValue = "";

    this.setState({
      filterToDate: "",
      filterFromDate: "",
      filterStatus: "",
      filterMachine: [],
      machineSelected: null,
    });
    await this.getFilterRecords();
    const rows = await this.getData();
    this.renderDataTable(rows);
    this.setState({
      filterLoading: false,
    });
    return false;
  };

  render() {
    return (
      <>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                View Updates
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">View Updates</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                <h3 className="btn btn-bg-light fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center">
                  <KTSVG
                    path="/media/icons/duotune/general/gen031.svg"
                    className="svg-icon-4 me-1"
                  />
                  Filter
                </h3>
              </div>
            </div>
            <div className="row mt-6">
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                <label className="form-label fs-4 fw-bold">From Date</label>
                <Datetime
                  ref={this.state.resetFrom}
                  closeOnSelect
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm"
                  value={this.state.filterFromDate}
                  onChange={(e: any) => {
                    const dt = e["_d"];
                    this.setState({
                      filterFromDate: dt,
                    });
                  }}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                <label className="form-label fs-4 fw-bold">To Date</label>
                <Datetime
                  ref={this.state.resetTo}
                  closeOnSelect
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm"
                  value={this.state.filterToDate}
                  onChange={(e: any) => {
                    const dt = e["_d"];
                    this.setState({
                      filterToDate: dt,
                    });
                  }}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                <label className="form-label fs-4  fw-bold">
                  Machines
                </label>
                <ReactSelect
                  className="react-select"
                  classNamePrefix="my-react-select"
                  placeholder=""
                  isMulti
                  name="filterMachine"
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  value={this.state.machineSelected}
                  onChange={(selected: any) => {
                    const value = selected?.map((e: any) => e.value);
                    this.setState({
                      machineSelected: selected,
                      filterMachine: value,
                    });
                  }}
                  options={Array.from(this.state.machinesDB).map(
                    (value: any) => {
                      let machineLabel = '';
                      if (value?.name) {
                        machineLabel = `${value?.name} (${value?.serialNumber})`;
                      } else if (value?.location?.name) {
                        machineLabel = `${value?.location?.name} (${value?.serialNumber})`;
                      } else {
                        machineLabel = `(${value?.serialNumber})`;
                      }
                      return {
                        value: value.id,
                        label: machineLabel,
                      };
                    }
                  )}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                <label className="form-label fw-bold fs-4">Status</label>
                <Select
                  ref={this.state.resetStatus}
                  className="react-select"
                  classNamePrefix="my-react-select"
                  name="filterStatus"
                  placeholder=""
                  onChange={(event: any) => {
                    this.setState({
                      filterStatus: event.value,
                    });
                  }}
                  options={Array.from(this.state.statusDB).map((value: any) => {
                    return {
                      value: value.id,
                      label: value.name,
                    };
                  })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                <button
                  type="submit"
                  onClick={this.searchData}
                  className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                >
                  {this.state.addLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!this.state.addLoading && (
                    <span className="indicator-label">ADD FILTER</span>
                  )}
                </button>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                <button
                  type="submit"
                  onClick={this.removeSearch}
                  className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                >
                  {this.state.filterLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!this.state.filterLoading && (
                    <span className="indicator-label">RESET FILTER</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <table
                    id="updateTable"
                    className="responsive-table table display align-items-center justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2"></th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Created At
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Software Info
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Launch Date
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Created By
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Status
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.abortOpen}
          onClose={this.abortClose}
          role="modal"
          center
        >
          <div className="modal-header p-2 pb-4 mb-4">
            <h4 className="modal-title fs-1">Abort Release</h4>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <label className="form-check form-check-custom form-check-solid me-5 d-flex mt-1 align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="CANCELLED"
                    value="CANCELLED"
                    defaultChecked={this.state.cancelStatus}
                    onChange={(event: any) => {
                      const { checked } = event.target;
                      if (checked === true) {
                        this.setState({
                          status: "CANCELLED",
                          cancelStatus: true,
                        });
                      } else {
                        this.setState({
                          cancelStatus: false,
                        });
                      }
                    }}
                  />
                  <span
                    className="form-check-label fw-bold ms-2 fs-4"
                    style={{ cursor: "pointer" }}
                  >
                    Cancel Release
                  </span>
                </label>
              </div>
              <div className="col-12 mt-2 pt-2">
                <label className="form-label required fw-bold d-block fs-4">
                  Reason
                </label>
                <div className="form-group">
                  <textarea
                    autoComplete="off"
                    className="form-control"
                    rows={3}
                    name="cancelReason"
                    value={this.state.cancelReason}
                    onChange={(event: any) => {
                      this.setState({
                        cancelReason: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer mt-6 p-0 justify-content-start">
            <button
              type="submit"
              onClick={() => {
                return this.submitUpdate(this.state.currentlyOpenRecord);
              }}
              className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
            >
              {this.state.loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please Wait..
                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                </span>
              )}
              {!this.state.loading && (
                <span className="indicator-label">SUBMIT</span>
              )}
            </button>
          </div>
        </Modal>

        <Modal
          open={this.state.abortMachineOpen}
          onClose={this.abortMachineClose}
          role="modal"
          center
        >
          <div className="modal-header p-2 pb-4 mb-4">
            <h4 className="modal-title fs-1 mt-5">Abort Machine Release - {this.state.currentlyOpenRecord?.machineDetail?.uuid ?? ""}</h4>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <label className="form-check form-check-custom form-check-solid me-5 d-flex mt-1 align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="READY_FOR_RELEASE"
                    value="READY_FOR_RELEASE"
                    defaultChecked={this.state.readyReleaseStatus}
                    onChange={(event: any) => {
                      const { checked } = event.target;
                      if (checked === true) {
                        this.setState({
                          status: "READY_FOR_RELEASE",
                          readyReleaseStatus: true,
                        });
                      } else {
                        this.setState({
                          readyReleaseStatus: false,
                        });
                      }
                    }}
                  />
                  <span
                    className="form-check-label fw-bold ms-2 fs-4"
                    style={{ cursor: "pointer" }}
                  >
                    Cancel Machine Release
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="modal-footer mt-6 p-0 justify-content-start">
            <button
              type="submit"
              onClick={() => {
                return this.submitAbort(this.state.currentlyOpenRecord);
              }}
              className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
            >
              {this.state.loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please Wait..
                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                </span>
              )}
              {!this.state.loading && (
                <span className="indicator-label">SUBMIT</span>
              )}
            </button>
          </div>
        </Modal>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </>
    );
  }
}

export default ViewUpdate;
