/**
 * @generated SignedSource<<b1667fd4668b9483e5512b2b57db8f10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CreateUpdate_getConfigVersionQuery$variables = {};
export type CreateUpdate_getConfigVersionQuery$data = {
  readonly getConfigVersion: ReadonlyArray<{
    readonly fileName: string;
    readonly url: string;
  }>;
};
export type CreateUpdate_getConfigVersionQuery = {
  response: CreateUpdate_getConfigVersionQuery$data;
  variables: CreateUpdate_getConfigVersionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GetFilesOutputObjectType",
    "kind": "LinkedField",
    "name": "getConfigVersion",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fileName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateUpdate_getConfigVersionQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CreateUpdate_getConfigVersionQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8a030a4b90a2d0f08cd59fb500fb7ac9",
    "id": null,
    "metadata": {},
    "name": "CreateUpdate_getConfigVersionQuery",
    "operationKind": "query",
    "text": "query CreateUpdate_getConfigVersionQuery {\n  getConfigVersion {\n    fileName\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "6c492a7dc4533366fd98fb7ce63a7371";

export default node;
